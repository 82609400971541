@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Quicksand:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  scroll-behavior: smooth; /* Active un défilement fluide */
  overflow: auto;
}

@layer components {
  [type="checkbox"]:checked {
    background-image: url("../public/img/checkmark.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}
.whit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden; /* Prevent horizontal overflow */
  overflow-y: auto; /* Allow vertical scrolling if needed */
  box-sizing: border-box; /* Include padding and border in the element’s total width and height */
}

main {
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  grid-template-rows: auto; /* Space between grid items */
}

.title-Arial {
  /* Title arial */
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 110px;
}

h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 61.04px;
  line-height: 70px;
}

h2 {
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}

h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 39.06px;
  line-height: 45px;
}

h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 31.25px;
  line-height: 36px;
}

h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
}

h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.Navbar {
  font-family: Arial;
  position: relative;
  width: 93.91%;
  height: 10.74%;
  margin-left: auto;
  top: 0;
  display: flex;
}

.Navlinks {
  position: relative;
  left: 5%;
  gap: 60px;
  bottom: 3px;
}

.Navlinks a {
  font-family: Arial;
  font-weight: bold;
}

.bg-main-blue {
  background-color: #1d428a;
}

.main-blue {
  color: #1d428a;
}

.hero-image-shadow {
  box-shadow: 70px 55px 5px rgba(37, 67, 133, 0.25);
}

@media (max-width: 1026px) {
  .hero-image-shadow {
    box-shadow: 0px 0px 0px rgba(37, 67, 133, 0.25);
  }
}

.rendezvous-button-shadow {
  box-shadow: 5px 20px 40px rgba(0, 0, 0, 0.35);
}

.hero-text-container {
  z-index: 5;
}

.hero-image-container {
  z-index: 2;
}

.macbook {
  z-index: 10;
}

#half-blue-circle {
  z-index: 1;
}

#above {
  z-index: 10;
}

/* Pour cacher la scrollbar dans tous les navigateurs */
.scrollbar-hide {
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Pour Chrome, Safari et Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.parent {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.div1 {
  grid-area: 2 / 2 / 7 / 4;
}
.div2 {
  grid-area: 4 / 5 / 6 / 8;
}
.div3 {
  grid-area: 1 / 1 / 3 / 8;
}

.box-service-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    4px 0px 4px rgba(0, 0, 0, 0.25), 0px -2px 5px 1px rgba(0, 0, 0, 0.3),
    0px 2px 5px -1px rgba(0, 0, 0, 0.3);
}

.button_partner {
  box-shadow: 0px 20px 45px rgba(0, 0, 0, 0.35);
}

.custom-marker li {
  position: relative;
  padding-left: 1.5rem;
  list-style: none;
}

.Navlinks {
  position: relative;
}
.Navlinks.AfterLink::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  height: 2px;
  width: 25px;
  margin: auto;
  background-color: #c8102e;
  transition: width 0.3s ease-in-out;
}

.custom-marker li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.8rem;
  width: 10px;
  height: 10px;
  background-color: #c8102e;
  border-radius: 50%;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes moreslide {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.logos {
  animation: 15s slide infinite linear;
}

.logoing {
  animation: 15s moreslide infinite linear;
}

.pause:hover {
  animation-play-state: paused;
}

.img-cont-logos {
  background-size: cover; /* Ensure the image covers the entire div */
  background-position: center; /* Center the image within the div */
  background-repeat: no-repeat; /* Prevent repeating the image */
}

.shadow-search-box {
  /* Rectangle 1 */
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 4px 2px rgba(0, 0, 0, 0.09),
    0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09),
    0px 32px 16px rgba(0, 0, 0, 0.09), inset 0px -23px 25px rgba(0, 0, 0, 0.15),
    inset 0px -36px 30px rgba(0, 0, 0, 0.15),
    inset 0px -79px 40px rgba(0, 0, 0, 0.1);
}

.testing {
  overflow: hidden;
}
.makeRound {
  border-radius: 20px;
}

.photo_ani {
  animation: upndown;
}

@keyframes upndown {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-8%);
  }
  65% {
    transform: translateY(4%);
  }
  80% {
    transform: translateY(-4%);
  }
  95% {
    transform: translateY(2%);
  }
  100% {
    transform: translateY(0%);
  }
}

.gobehind {
  position: relative;
  z-index: 0;
}

.goinfront {
  position: relative;
  z-index: 20;
}

.hidescrollbar::-webkit-scrollbar {
  width: 0; /* Hides the scrollbar */
  height: 0; /* Also hides horizontal scrollbar */
}

.zIndex {
  z-index: 1999999999;
}

.classInput {
  width: 100%;
}

/* bg color des card */
.bg1 {
  background: #1d428a;
}
.bg2 {
  background: linear-gradient(90deg, #1d428a 11%, #c8102e 72.5%);
}
.bg3 {
  background: linear-gradient(90deg, #c8102e 82%, #c9112f 93.5%);
}

/* Personnalisation scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #1d428a;
  border-radius: 20px;
  border: 3px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0f2c5a;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Pour Firefox */
.scrollable {
  scrollbar-color: #1d428a #f1f1f1;
  scrollbar-width: thin;
}


